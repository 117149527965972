/** *   META DATA 🤖   ***/
import { useURL } from '~/composables/useURL'
import { imageUrl } from '~/helpers/useImageSetter'

const getPhotoForMeta = (metaImg) => {
  let path = ''

  if (metaImg) {
    const imgAttr = {
      resize: 'crop',
      width: 600,
      height: 315,
    }

    path = imageUrl(metaImg, imgAttr)
  }

  return path
}

export const getOpenGraph = (
  title,
  description,
  metaImg = 'production/uploads/photos/31288/CET_ViewB15.JPG',
) => {
  const { t } = useI18n({ useScope: 'global' })
  const route = useRoute()
  const config = useRuntimeConfig()

  const openGraph = []
  const { getBaseURL } = useURL()
  const url = `${getBaseURL()}${route.path}`

  if (!description) {
    description = t('home.meta_description')
  }

  openGraph.push({
    hid: 'og:url',
    property: 'og:url',
    content: url,
  })

  openGraph.push({
    hid: 'og:description',
    property: 'og:description',
    content: description,
  })

  openGraph.push({
    hid: 'og:site_name',
    property: 'og:site_name',
    content: config.public.siteName,
  })

  openGraph.push({
    hid: 'og:title',
    property: 'og:title',
    content: title,
  })

  openGraph.push({
    hid: 'og:type',
    property: 'og:type',
    content: 'website',
  })

  openGraph.push({
    hid: 'og:image',
    property: 'og:image',
    content: getPhotoForMeta(metaImg),
  })

  openGraph.push({
    hid: 'og:image:height',
    property: 'og:image:height',
    content: '315',
  })

  openGraph.push({
    hid: 'og:image:width',
    property: 'og:image:width',
    content: '600',
  })

  return openGraph
}

export const getTwitter = (
  title,
  description = '',
  metaImg = 'production/uploads/photos/31288/CET_ViewB15.JPG',
) => {
  const config = useRuntimeConfig()
  const { t } = useI18n({ useScope: 'global' })
  const twitter = []

  if (!description) {
    description = t('home.meta_description')
  }

  twitter.push({
    hid: 'twitter:description',
    property: 'twitter:description',
    content: description,
  })

  twitter.push({
    hid: 'twitter:site',
    property: 'twitter:site',
    content: config.public.twitter,
  })

  twitter.push({
    hid: 'twitter:title',
    property: 'twitter:title',
    content: title,
  })

  twitter.push({
    hid: 'twitter:card',
    property: 'twitter:card',
    content: 'summary_large_image',
  })

  twitter.push({
    hid: 'twitter:image',
    property: 'twitter:image',
    content: getPhotoForMeta(metaImg),
  })

  twitter.push({
    hid: 'twitter:image:height',
    property: 'twitter:image:height',
    content: '315',
  })

  twitter.push({
    hid: 'twitter:image:width',
    property: 'twitter:image:width',
    content: '600',
  })

  return twitter
}

export const getImgSrc = (
  metaImg = 'production/uploads/photos/31288/CET_ViewB15.JPG',
) => {
  const imageSrc = []

  imageSrc.push({
    rel: 'image_src',
    href: getPhotoForMeta(metaImg),
  })

  return imageSrc
}
